import React from "react"
import { navigate } from "gatsby"
import {
  getCourseNavigation,
  useCourseStore,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Column from "../../../../../components/column"
import Columns from "../../../../../components/columns"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Letter from "../../../../../components/letter"
import TaskSidebar from "../../../../../components/task-sidebar"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"
import TextPicker from "../../../../../components/text-picker"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "reiseerlebnisse",
}

const Page = () => {
  const { submitForm } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          event,
          ...taskProps,
        })
        navigate("/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/tagebuch")
      }}
      navigation={navigation}
      progress={progress}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Reiseerlebnisse in Emojis" />
      <Stack>
        <Heading as="h2" level={2}>
          Reiseerlebnisse in Emojis
        </Heading>
        <Paragraph>
          Was hat Hölderlin am Tag seiner Anreise alles erlebt? Übertrage das
          Erzählte in eine Bildergeschichte aus Emojis.
        </Paragraph>

        <TaskSidebar
          main={
            <Letter>
              <Stack>
                <Columns>
                  <Column>
                    <Text size={3}>
                      Montags, den 2ten Juni reißt ich ab. Es war ein schöner
                      belebender Morgen. Noch nie war mir so wohl, als da ich,
                      eine halbe Stunde von hier, den Berg hinunterritt und
                      unter mir Knitlingen lag, und weit hinaus die geseegneten
                      Gefilde der Pfalz.
                    </Text>
                  </Column>
                  <Column width="content">
                    <TextPicker id="q-01" />
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Text size={3}>
                      Mit dieser Heiterkeit sezte ich meinen Weg fort und jezt
                      war ich in Bruchsaal. Ich wartete im Wirtshaus auf Vetter
                      Blum [der Verlobte seiner Cousine]. Ich wartete bis eins,
                      es kam kein Blum, wartete bis zwei, bis drei – noch nicht!
                      Jezt war ich ärgerlich.
                    </Text>
                  </Column>
                  <Column width="content">
                    <TextPicker id="q-02" />
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Text size={3}>
                      Gefallen hatte mirs in Bruchsaal ohnehin nicht, unter
                      dummen Pfaffen, und steiffen Residenzfrazen– mein Pferd
                      hatt ich nur auf diesen Tag gemietet, der Weg nach Speier
                      war lang, die Zeit kurz, die Straße mir unbekannt. Was war
                      zu thun?
                    </Text>
                  </Column>
                  <Column width="content">
                    <TextPicker id="q-03" />
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Text size={3}>
                      Ich schikte den Mann, den ich bei mir hatte, um das Pferd
                      zurükzunehmen, nach Haus, sezte mich aufs Pferd, und flug
                      Speier zu!
                    </Text>
                  </Column>
                  <Column width="content">
                    <TextPicker id="q-04" />
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Text size={3}>
                      Ich passirte meist dike, schauerliche Waldungen, so daß
                      ich außer meinem Weg kaum drei Schritte weit um mich sehen
                      konnte. So dik habe ich in Wirtemberg noch keine Wälder
                      gesehn. Kein Sonnenstral drang durch. Endlich kam ich
                      wieder ins Freie. Ich hielt lange still.
                    </Text>
                  </Column>
                  <Column width="content">
                    <TextPicker id="q-05" />
                  </Column>
                </Columns>
                <Columns>
                  <Column>
                    <Text size={3}>
                      Der neue, unerwartete Anblik einer so ungeheuren Ebene
                      rührte mich. Felder, deren Früchte schon halb gelb waren –
                      Wiesen wo das Gras, das noch nicht abgemäht war, sich
                      umneigte – so hoch, so reichlich stand es – und dann der
                      weite, schöne, blaue Himmel über mir! Ich war so entzükt ,
                      daß ich vieleicht noch dort stände mit meinem Roß, wann
                      mir nicht gerade vor mir das fürstlichbischöfliche
                      Lustschloß Waaghäußel in die Augen gefallen wäre …
                    </Text>
                  </Column>
                  <Column width="content">
                    <TextPicker id="q-06" />
                  </Column>
                </Columns>
              </Stack>
            </Letter>
          }
          sidebar={
            <Note variant="task">
              Wähle für jeden Abschnitt ein passendes Emoji. Gehe dazu auf die
              Box mit dem Fragezeichen und wähle ein Emoji aus.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
